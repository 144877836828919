import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    gridItem: {
        maxWidth: '345px',
        maxHeight: '244px',
        // [theme.breakpoints.down('sm')]: {
        //     maxWidth: '313px',
        //     maxHeight: '244px',
        // },
    },
}))