import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '345px',
        maxHeight: '324px',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
        transition: `all 0.5s ease-in-out`,
        '&:hover': {
            transform: `scale(1.03)`,
        },
        cursor: 'pointer',
        backgroundColor: 'rgba(33, 203, 243, .1)'
    },
    card: {
        display: 'flex', flexDirection: 'column'
    },
    cardImage: {
        // height: 'auto',
        height: '244px',
        maxWidth: '325px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        objectFit: 'contain'
    },
    cardContent: {
        display: 'flex', flexDirection: 'column',
        overflow: 'hidden',
    },
}))