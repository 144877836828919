import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
    container: {
        height: '90vh',
        [theme.breakpoints.down('lg')]: {
            height: '50vh',
        },
        [theme.breakpoints.down('sm')]: {
            height: '30vh',
        },
        backgroundColor: "white",
        color: "white",
        fontWeight: 'lighter',
        padding: '5px 0',
        marginBottom: '16px',
        marginTop: '2px',
    },
}))