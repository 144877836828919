//auth
export const START_LOADING_AUTH = 'START_LOADING_AUTH'
export const END_LOADING_AUTH = 'END_LOADING_AUTH'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const LOGOUT = 'LOGOUT'

//route
export const START_LOADING_ROUTE = 'START_LOADING_ROUTE'
export const END_LOADING_ROUTE = 'END_LOADING_ROUTE'
export const FETCH_ROUTE = 'FETCH_ROUTE'
export const SELECTED_ROUTE = 'SELECTED_ROUTE'

//toast
export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_HIDE = 'TOAST_HIDE'
export const TOAST_CONFIG = 'TOAST_CONFIG'
export const TOAST_PROMISE = 'TOAST_PROMISE'

//product
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const FETCH_ALL = 'FETCH_ALL'
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const LIKE = 'LIKE'
export const COMMENT = 'COMMENT'
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'

//portfolio
export const CREATE_PORTFOLIO = 'CREATE_PORTFOLIO'
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO'
export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO'
export const FETCH_ALL_PORTFOLIO = 'FETCH_ALL_PORTFOLIO'
export const FETCH_PORTFOLIO = 'FETCH_PORTFOLIO'
export const START_LOADING_PORTFOLIO = 'START_LOADING_PORTFOLIO'
export const END_LOADING_PORTFOLIO = 'END_LOADING_PORTFOLIO'

//project
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const FETCH_ALL_PROJECT = 'FETCH_ALL_PROJECT'
export const FETCH_PROJECT = 'FETCH_PROJECT'
export const START_LOADING_PROJECT = 'START_LOADING_PROJECT'
export const END_LOADING_PROJECT = 'END_LOADING_PROJECT'

//projectDetail
export const CREATE_PROJECT_DETAIL = 'CREATE_PROJECT_DETAIL'
export const UPDATE_PROJECT_DETAIL = 'UPDATE_PROJECT_DETAIL'
export const DELETE_PROJECT_DETAIL = 'DELETE_PROJECT_DETAIL'

export const FETCH_ALL_PROJECT_DETAIL = 'FETCH_ALL_PROJECT_DETAIL'
export const FETCH_PROJECT_DETAIL = 'FETCH_PROJECT_DETAIL'
export const FETCH_PROJECT_DETAIL_BY_SEARCH = 'FETCH_PROJECT_DETAIL_BY_SEARCH'
export const FETCH_PROJECT_DETAIL_BY_PROJECT_ID = 'FETCH_PROJECT_DETAIL_BY_PROJECT_ID'
export const FETCH_PROJECT_DETAIL_BY_PORFOLIO_ID = 'FETCH_PROJECT_DETAIL_BY_PORFOLIO_ID'
export const FETCH_PROJECT_DETAIL_FOR_SHOW_HEADER = 'FETCH_PROJECT_DETAIL_FOR_SHOW_HEADER'

export const START_LOADING_PROJECT_DETAIL = 'START_LOADING_PROJECT_DETAIL'
export const END_LOADING_PROJECT_DETAIL = 'END_LOADING_PROJECT_DETAIL'

//route
export const START_LOADING_UPLOAD = 'START_LOADING_UPLOAD'
export const END_LOADING_UPLOAD = 'END_LOADING_UPLOAD'
export const UPLOAD_PROCESSING = 'UPLOAD_PROCESSING'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
export const UPLOAD_MULTI_COMPLETE = 'UPLOAD_MULTI_COMPLETE'
