import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

import { fill, scale } from "@cloudinary/url-gen/actions/resize";

import useStyles from './styles'

const CardItem = ({ item, onClick }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const cld = new Cloudinary({
        cloud: {
            cloudName: 'art-sunday'
        }
    });

    console.log('[item.thumbnail]', item)
    const replace = item.thumbnail.replace('https://res.cloudinary.com/art-sunday/image/upload/', '')
    const split = replace.split('.')
    console.log('[split]', split)
    const indexOf = split[0].indexOf('/')
    console.log('[indexOf]', indexOf)

    const publicID = decodeURIComponent(split[0].substr(indexOf + 1, split[0].length))
    console.log('[publicID]', publicID)

    const myImage = cld.image(publicID);

    // Resize to 250 x 250 pixels using the 'fill' crop mode.
    myImage.resize(scale().width('345').height('244'));

    const handleClickItem = () => {
        if (onClick) {
            onClick(item)
        }
    }

    return (
        <Card className={classes.cardContainer} elevation={0} onClick={handleClickItem}>
            <Box className={classes.card}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <AdvancedImage className={classes.cardImage} cldImg={myImage} />
                    {/* <CardMedia
                        component='img'
                        className={classes.cardImage}
                        image={item.thumbnail}
                        alt='portfolio image'
                    /> */}
                </Box>
                <Box className={classes.cardContent}>
                    <CardContent sx={{ padding: 1, mb: -3, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='body1' component='p'>
                            {item.title}
                        </Typography>
                        <Typography variant='body2' color="text.secondary" component="p">
                            {moment(new Date(item.createdAt)).format('HH:MM MMM DD, YYYY')}
                        </Typography>
                    </CardContent>
                </Box>
            </Box>
        </Card>
    )
}

export default CardItem