import React from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";
import { fill, scale } from "@cloudinary/url-gen/actions/resize";

import useStyles from './styles'

const CardItem = ({ item, onClick }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const cld = new Cloudinary({
        cloud: {
            cloudName: 'art-sunday'
        }
    });

    const replace = item.thumbnail.replace('https://res.cloudinary.com/art-sunday/image/upload/', '')
    const split = replace.split('.')
    const indexOf = split[0].indexOf('/')

    const publicID = decodeURIComponent(split[0].substr(indexOf + 1, split[0].length))
    const myImage = cld.image(publicID);

    // Resize to 250 x 250 pixels using the 'fill' crop mode.
    myImage.resize(scale().width('345').height('244'));

    const handleClickItem = () => {
        if (onClick) {
            onClick(item)
        }
    }

    return (
        <Card className={classes.cardContainer} elevation={0} onClick={handleClickItem}>
            <Box className={classes.card}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <AdvancedImage className={classes.cardImage} cldImg={myImage} alt={item.name} />
                    {/* <CardMedia
                        component='img'
                        className={classes.cardImage}
                        image={item.thumbnail}
                        alt='portfolio image'
                    /> */}
                </Box>
                <Box className={classes.cardContent}>
                    <CardContent>
                        {/* <Typography variant='body1' color={'text.secondary'}>
                            {item.name}
                        </Typography> */}
                        <Typography variant='body2' component='p' paragraph >
                            {item.title}
                        </Typography>
                    </CardContent>
                </Box>
            </Box>
        </Card>
    )
}

export default CardItem